<template>
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer vs-button-primary vs-button-filled">
    <vs-button class="p-0">
      <p>{{ $t('Merchants') }}</p>
    </vs-button>
    <vs-dropdown-menu class="noprint">
      <vs-dropdown-item v-for="merchant in merchantsPickupPoints ? merchantsPickupPoints : merchants" :key="merchant.index" @click="loadOrders(merchant)">
        <p :class="$route.query.merchant === merchant.name ? 'text-primary font-medium' : ''">{{merchant.name}}</p>
      </vs-dropdown-item>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import axios from 'axios'
import JsBarcode from 'jsbarcode'
import i18nData from '../../../i18n/i18nData'
import { sendRequest } from '../../../http/axios/requestHelper'

export default {
  props: ['selected', 'merchantsPickupPoints'],
  data () {
    return {
      merchants: [],
      selectedFlag: false,
      showPrintDropdown: false
    }
  },
  watch: {
    selected (val) {
      this.showPrintDropdown = false
      if (val.length !== 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    loadOrders (merchant) {
      this.$emit('merchantFilter', merchant)
    },
    loadMerchants () {
      sendRequest(true, false, this, `api/v1/merchants/?has_counter_returns_in=${this.$route.params.warehouseID}&limit=100`, 'get', null, true,
        (response) => {
          this.merchants.unshift({name: 'All', id: 0})
          this.merchants.push(response.data.results)
          this.merchants = this.merchants.flat()
        }
      )
    },
    printLabels (query) {
      this.showPrintDropdown = false
      const dataFromFrontEnd = []
      const dataToBackEnd = []
      this.selected.forEach(element => {
        if (element.name || element.tracking_number[0] === '4') {
          dataFromFrontEnd.push(element)
        } else {
          dataToBackEnd.push(element) 
        }
      })
      if (dataToBackEnd.length > 0) {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: '#print-btn-loading',
          scale: 0.45
        })
        axios({
          url: `${process.env.VUE_APP_API_URL}/api/v1/orders/shipping-label/?type=pdf&tracking_numbers=${dataToBackEnd.map(item => item.tracking_number)}${query ? query : ''}`,
          method: 'GET',
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'ShipBlu AWBs.pdf')
          document.body.appendChild(link)
          link.click()
          this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
        }).catch((error) => {
          if (error && error.response && error.response.status === 400 && error.response.status) {
            this.$vs.notify({
              color:'danger',
              title: i18nData[this.$i18n.locale]['Failed'],
              text: i18nData[this.$i18n.locale]['Cannot generate a shipping label for unzoned orders.'],
              position: 'top-center'
            })
          }
          this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
        })
      }
      if (dataFromFrontEnd.length > 0) {
        this.selected = dataFromFrontEnd
        JsBarcode('.barcode').init()
        window.print()
      }
    }
  },
  created () {
    this.loadMerchants()
  }
}
</script>